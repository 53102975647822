import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import bgImage from "../assets/loader/bg.png";
import disketteImage from "../assets/loader/diskette.png";
import slider from "../assets/loader/slider.png";

const Section = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  position: relative;
  overflow: hidden;
  background-image: url(${bgImage});
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;

  .mobile {
    display: none;
  }

  .small-screen {
    display: none;
  }

  @media (max-width: 64em) {
    .mobile {
      display: block;
    }
  }

  @media (max-width: 42em) {
    .small-screen {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const DisketteImage = styled.img`
  width: 538.88px;
  /* width: 70%; */
  margin: 0;
  padding: 0;
  position: absolute;
  top: ${({ isPlaying }) => (isPlaying ? "50%" : "40%")};
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 2.5s ease-in-out;

  @media (max-width: 64em) {
    width: 276.84px;
    height: 276.84px;
    top: ${({ isPlaying }) => (isPlaying ? "60%" : "45%")};
  }
`;

const SliderImage = styled.img`
  width: 659.05px;
  /* width: 100%; */
  margin-top: 70%;
  z-index: 100;
  @media (max-width: 64em) {
    width: 338.57px;
    height: 116.75px;
  }
`;

const PlayButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  padding: 10px 40px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
  position: absolute;
  right: 12%;
  bottom: 5%;
  z-index: 150;
  @media (max-width: 64em) {
    right: 18%;
    bottom: 3%;
    padding: 5px 15px;
    font-size: 12px;
  }

  @media (max-width: 42em) {
    right: 7%;
  }
`;

const Title = styled.p`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const TextBox = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 42em) {
    /* width: 90%; */
  }
`;

const InlinePlayButton = styled(PlayButton)`
  position: static;
  margin-left: 10px;
`;

const Loader = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      const timer = setTimeout(() => {
        navigate("/main");
      }, 2500); // время анимации в миллисекундах

      return () => clearTimeout(timer);
    }
  }, [isPlaying, navigate]);

  return (
    <Section>
      <Container>
        <Box>
          <DisketteImage src={disketteImage} isPlaying={isPlaying} />
          <SliderImage src={slider} />
          <PlayButton onClick={handlePlayClick}>PLAY</PlayButton>
        </Box>
        <TextBox>
          <Title>
            If you're ready to change the matrix,
            <br className="small-screen" /> press
            <InlinePlayButton>PLAY</InlinePlayButton>.<br className="mobile" />{" "}
            There's no turning back.
          </Title>
        </TextBox>
      </Container>
    </Section>
  );
};

export default Loader;
