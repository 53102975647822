import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header";
import Welcome from "./components/welcome";
import SecondWelcome from "./components/welcome2";
import About from "./components/about";
import Benefits from "./components/benefits";
import Tokenomic from "./components/tokenomic";
import Footer from "./components/footer";
import Test from "./components/test";
import Transition from "./components/transition";
import Loader from "./pages/Loader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Loader />} />
        <Route path="/main" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
